// 使用媒体查询的包裹组件
import React, { CSSProperties, ReactChild } from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';
interface WrapperProps {
  // children
  children: ReactChild;
  // style
  style?: CSSProperties;
  // className
  className?: string;
}
const Wrapper = (props: WrapperProps) => {
  const { children, style, className } = props;
  return (
    <div className={classnames(styles.wrapper, className)} style={style}>
      <div className={styles.container}>{children}</div>
    </div>
  );
};

export default Wrapper;
